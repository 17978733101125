<template>
    <section>
        <InsideHeader :title="pageData.page_heading" :bredcrumb="breadCrumb" />
        <div class="section py-5 gallery-page ">
            <div class="container">                
                <div class="row gallery">                                          
                    
                    <div class="col-sm-4 col-md-3 col-lg-3 col-6" v-for="(item, index) of galleryData" :key="index">
                         <router-link :to="`/gallery/${item.id}/photos`" class="galbox">
                            <figure>
                                <div class="goverlay">                                   
                                    <h3 class="galtitle">{{ item.title }}</h3>
                                </div>
                            
                                <img :src="filePath + '/large/'+ item.image_name" :alt="item.title" class="img-fluid">
                            </figure>
                         </router-link>
                    </div>
                               
                </div>
            </div>
        </div>
    </section> 
</template>

<script>
import InsideHeader from "@/components/InsideHeader.vue";
import NoRecord from "@/components/NoRecord.vue";
import Translation from '../../../public/translation.json'


export default {
    components: {
        InsideHeader, NoRecord
    },
    data(){
        return {           
            index: null,
            breadcrumb: [],
            dir_path: '',
            defaultLang: localStorage.getItem('_store_lang') || 'en',
            pageData: [],
            galleryData: [],
            filePath: ''
        }
    },   
    methods: {
        showImg(index) {
            this.index = index
            this.visible = true
        },
        pageContent(field){
            return Translation.content[this.defaultLang][field]
        },
        getPageData() {
            let slug = 'gallery'
            this.$store.dispatch('getPageData', slug).then(res=>{
               if(res.data.status === true) {
                    this.pageData = res.data.data
                    if(res.data.dir_path) {
                        this.dir_path = res.data.dir_path
                    }
                }
            })
            .catch(err => {
                console.log(err)
                this.$router.push({name: '404'})
            })
        },
        getData(){ 
            let type = 0;
            if(this.$store.state.auth.token)
                type = 1
                     
            this.$http.get(`/gallery?type=${type}`).then(res=>{
                if(res.status === 200 && res.data){
                    if(res.data.data){
                        let fetchData = res.data.data
                        this.galleryData = fetchData;
                        // this.galleryData = fetchData.filter(res=>{
                        //     return res.user_type 
                        // })
                    }
                    if(res.data.path)
                        this.filePath = res.data.path
                }
            })
        }
    },    
    computed: {
        breadCrumb(){
            return this.breadcrumb = [
                {title: this.pageContent('home'), path:'/'},
                {title: this.pageContent('gallery'), path:'#'}
            ]
        }       
    },
    created: function(){
        this.getPageData()
        this.getData()
    },
}
</script>